import React from 'react'
import Banner from '../components/banner'
import Intro from '../components/introduction'
import Stress from '../components/stress'
import Footer from '../components/footer'
import Explore from '../components/additional'
import Navig from '../components/navig'

function Home() {
  return (
    <>
      <Navig/>
      <Banner/>
      <Intro />
      <Explore />
      <Stress />
      <Footer />
    </>
  )
}

export default Home
