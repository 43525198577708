import React from 'react'
import Footer from '../components/footer'
import Report from '../components/contactForm'
import Navig from '../components/navig'
import ContactUs from '../components/contactus'

function Contact() {
  return (
    <>
      <Navig />
      <Report/>
      {/* <ContactUs/> */}
      <Footer />
    </>
  )
}

export default Contact
