import React from 'react'
import giraff from '../images/cars.jpg'

function aboutus() {
  return (
    <>
        <section className='about-title'>
      <h2>Who we are?</h2>
    </section>
        <div className='vision-container'>
          <div>
            <h2 className='vision-title'>ABOUT US</h2>
            <div className='vision-design'>___________</div>
            <p className='vision-para'>A fully established and legally registered company by Uganda Registration
service Bureau (URSB) offering a variety of services in the range of Airport pickup & drop Ups, Game
drives across East Africa, Self drive hirings, Safari travels and ceremonial car hirings for parties.<br></br> <br></br>Raccoon
Tours and Travels is operated and managed by experienced, skilled and well trained staff members for
the best of required customer satisfaction.<br></br><br></br> Our driving force is the passion to provide efficient and
personalized customer care service to all those who engage with us. 
</p>
          </div>
          <div>
            <img className='vision-img' alt='cars' src={giraff} width={450} height={350} ></img>
          </div>
        </div>
    </>
  )
}

export default aboutus
