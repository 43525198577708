import React, { useRef } from 'react'
import { Form, Button } from 'react-bootstrap';
import '../App.css'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import emailjs from '@emailjs/browser';

function Report() {
  const sep = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ym4ymeg', 'template_4olcb6r', sep.current, {
        publicKey: 'Etp2lEyNnnfE7__lU',
      })
      .then(
        () => {
          alert('Thanks for the  message we will get back to you shortly');
        },
        (error) => {
          alert('FAILED...', error.text);
        },
      );
    }
  return (
    <>
    <section className='form-title'>
      <h2>Contact Us</h2>
    </section>
    <section className='form-container'>
      <div>
        <h2 className='talk-us'>Talk to Us</h2>
        <Form className='form-content' ref={sep} onSubmit={sendEmail}>
          <FloatingLabel controlId="floatingInput" className="mb-3" label='Name Or Company'>
            <Form.Control name='to_name' id='to_name' type="text" placeholder="Name or Company" required />
          </FloatingLabel>
          <FloatingLabel controlId="floatingEmail" className='mb-3' label='Email'>
            <Form.Control name='from_name' id='from_name' type="email" placeholder="name@email.com" required />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" className='mb-3' label='Message' >
            <Form.Control name='message' id='message' as="textarea" placeholder="Message" style={{ height: '100px' }} required/>
          </FloatingLabel>
          <Button className='message-btn' as="input" type="submit" value="Send Message" />
      </Form>
      </div>
      <div className='contact1-control'>
        <h2>Contact Info</h2>
        <h3>Address:</h3>
          <p>P.O Box 860599 Kireka</p>
          <p>Kirinya - Bweyogerere, Kampala, Uganda</p>
        <h3>Email Us:</h3>
          <p>info@raccoontoursandtravel.com</p>
          <p>raccoontoursandtravel@gmail.com</p>
        <h3>Call Us:</h3>
          <p>+256 705 184 879 | +256 774 045 400</p>
      </div>
    </section>
    </>
  )
}

export default Report
