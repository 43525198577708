import React from 'react'

function Core() {
  return (
    <>
        <div className='core-container'>
            <div className='core-values'>
                <h2>Core Values</h2>
                <h4>Commitment:</h4>
                <p>We are committed to providing an unparalleled level of service and value with individually customized travel and safaris management services through the provision
                    of unmatched and memorable trip experiences on the road.</p>
                <h4>Excellence:</h4>
                <p>We are committed to being a high-performance tour and travel company by staying focused on total customer satisfaction. We continuously analyze our processes and
                    ourselves in order to become the best of the best in providing tour and travel customer needs in Uganda and East Africa as whole.</p>
                <h4>Friendliness:</h4>
                <p>To provide our esteemed clients a true and wonderful experience of the fact that Uganda is the Pearl of Africa through our warm, generous, helpful and hospitable
                    well-staffed members that gives a corporate treatment to all our clients that comes across on the board.</p>
                <h4>Integrity:</h4>
                <p>We insist and believe in being open, honest with fair relationships with all our customers, each other and business partners and we continue believing that this is the
                    only tenet of a successful business</p>
            </div>
            <div className='core-mission'>
                <h2>Company Mission</h2>
                <p> To provide tailored , exclusive and
                memorable travel safaris and
                adventurous services that meet
                customers dreams on time and beyond
                their expectations as clients for maxima
                memorable moments of life.</p>
                <h2>Company Vision</h2>
                <p>To be the Super best company in
                offering our clients with the best travel
                needs and leisure travel activities
                through creating lifelong memories
                through unique and unexceptional
                travelling experiences in Uganda and
                East Africa as whole.</p>
                <h2>Company Objectives</h2>
                <p>-Offer the experience of connecting with nature and native communities.</p>
                <p>-Promote among our employees respect, protection and conservation of the environment.</p>
                <p>-Customer satisfaction with the experiences.</p>
                <p>-Increase market share of ethnic experiences.</p>
                <p>-Achieve and maintain commercial sustainability.</p>
                <p>-Provide excellent customer service with best memorable experience.</p>
            </div>
        </div>
    </>
  )
}

export default Core
