import React from 'react'
import contact from '../pages/contact'

function welcome () {
  return (
    <>
        <div className='banner'>
            <h2 className='welcome-note'>
                Experience Something New Every Moment.
            </h2>
            <span className='welcome-note-span'>
                Discover your next great adventure, become a Raccoon to get Started.
                <br></br>
                <div className='btn-div'>
                <button className='btn-explore1' onClick={contact}>
              Contact Us
            </button>
                </div>
            </span>
        </div>
    </>
  )
}

export default welcome 

