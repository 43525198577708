import React from 'react'
import { Route, BrowserRouter, Routes} from 'react-router-dom'
import AOS from 'aos'
import './App.css'
import { useEffect } from 'react'
import Home from './pages/home'
import About from './pages/about'
import Service from './pages/service'
import Contact from './pages/contact'

function App() {

  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/service' element= {<Service />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
