import React from 'react'

function Footer() {
  return (
   <>
    <section className='footer1-container'>
        <p> Kirinya - Bweyogerere, Kampala, Uganda | Phone: +256 705 184 879 | +256 774 045 400 <br></br> Email: info@raccoontoursandtravel.com | raccoontoursandtravel@gmail.com</p>
        <p>Copyright © 2024 Raccoon Tours and Travel</p>
    </section>
   </>
  )
}

export default Footer
