import React from 'react'
import tired from '../images/tired.jpg'

function stress() {
  return (
   <>
    <section className='stress-content'>
        <img className='stress-img' src={tired} width={300} height={500} alt='tired'></img>
        <div className='stress'>
            <h2 className='stress-title'>Are you stressed about your Tours & Travel options?</h2>
            <p className='stress-para'>
            Our team is a committed one to serve and offer our clients an unforgettable movements and travels<br></br>
            across all the boarders of East Africa through exciting, peaceful, authentic and enjoyable time in Uganda<br></br>
            and East Africa as whole. We have chosen exceptional and those known vehicles for comfortable and<br></br>
            luxurious across the globe. The range incudes Landcruiser , Range Rouver Sport and Super Custom all<br></br>
            know for good and comfy seats to create unforgettable memories to our clients along the road .
            </p>
        </div>
    </section>
   </>
  )
}

export default stress
