import React from 'react'
import Footer from '../components/footer'
import { IoMdAirplane } from "react-icons/io";
import { FaCarSide } from "react-icons/fa";
import { FaCar } from "react-icons/fa6";
import { FaLuggageCart } from "react-icons/fa";
import { FaGlassCheers } from "react-icons/fa";
import { MdOutdoorGrill } from "react-icons/md";
import Navig from '../components/navig';

function Service() {
  return (
    <>
      <Navig/>
      <div className='service-container'>
        <h2>Services</h2>
        <span>At Raccoon Tours And Travel </span>
        <p>
        We are committed to providing an unparalleled level of service and value<br></br>
        with individually customized travel and safaris management services through the provision<br></br>
        of unmatched and memorable trip experiences on the road.
        </p>
      </div>
      <div className='service-items'>
        <div className='flex-item'>
          <h5><IoMdAirplane /></h5>
          <h3>Airport Pick Ups & Drop Offs</h3>
        </div>
        <div className='flex-item'>
          <h5 className='plane'><FaCarSide /></h5>
          <h3>Game drives across East Africa</h3>
        </div>
        <div className='flex-item'>
          <h5><FaCar /></h5>
          <h3>Self drive hirings</h3>
          <p>Craft your own personalized adventure with our help.</p>
        </div>
        <div className='flex-item'>
          <h5><FaLuggageCart /></h5>
          <h3>Safari travels</h3>
        </div>
        <div className='flex-item'>
          <h5><FaGlassCheers /></h5>
          <h3>Leisure & Luxurious Travels</h3>
        </div>
        <div className='flex-item'>
          <h5><MdOutdoorGrill /></h5>
          <h3>Ceremonial car hirings for parties</h3>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Service
