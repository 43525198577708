import React from 'react'

function Explore() {
  return (
    <>
        <section className='explore-container'>
            <h2>EXPLORER THE WORLD</h2>
            <p>
            As the world beckons with its wonders, one destination stands out as an 
            extraordinary starting point for your global adventures – Uganda. Nestled in 
            the heart of East Africa, Uganda is a country of remarkable diversity, offering a
            captivating blend of landscapes, cultures, and wildlife. But the allure of Uganda 
            is only the beginning of a journey that can take you to the far reaches of our 
            incredible planet.
            </p>
            <button className='btn-explore'>
              Learn More
            </button>
        </section>
    </>
  )
}

export default Explore
