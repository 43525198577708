import React from 'react'
import Footer from '../components/footer'
import Aboutus from '../components/aboutus'
import '../App.css'
import Core from '../components/core'
import Navig from '../components/navig'

function About() {
  return (
    <>
      <div className='navigation-container'>
        <Navig />
      </div>
      <Aboutus />
      <Core />
      <Footer />
    </>
  )
}

export default About
