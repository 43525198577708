import React from 'react'
import water from '../images/water.jpg'
import bag from '../images/bag.jpg'
import coup from '../images/coup.jpg'

function intro() {
  return (
    <>
        <div className='short-note'>
            <span>About Racoon Tours & Travel</span>
          <div className='content'>
            <p>
            A fully established and legally registered company by Uganda Registration
            service Bureau (URSB) & Uganda Tourism Board (UTB) offering a variety of services in the range of Airport pickup & drop Ups, Game
            drives across East Africa, Self drive hirings, Safari travels and ceremonial car hirings for parties.<br></br><br></br>Raccoon
            Tours and Travels is operated and managed by experienced, skilled and well trained staff members for
            the best of required customer satisfaction.
            </p>
            <img className='img1' src={coup} alt='img1'width={400} height={300}></img>
            <img className='img2' src={bag} alt='img2' width={400} height={300}></img>
            <img className='img3' src={water} alt='img3' width={300} height={200}></img>
          </div>
        </div>
    </>
  )
}

export default intro
